// Vue
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// Views
import BlankView from './views/BlankView'
// import DeeplinksView from './views/DeeplinksView'
// import RegisterView from './views/RegisterView'
// import RegisterViewEN from './views/RegisterViewEN'
// import EvaluationView from './views/EvaluationView'
import RegistrationView from './views/RegistrationView'
import RegistrationViewEN from './views/RegistrationViewEN'
const routes = [
  { 
    path: '*',
    redirect: '/' 
  },
  { 
    path: '/', 
    component: BlankView
  },
  /* { 
    path: '/deeplinks',
    component: DeeplinksView
  }, 
  { 
    path: '/auswertung', 
    component: EvaluationView
  },
  { 
    path: '/registrieren/:data', 
    component: RegisterView
  },
  { 
    path: '/register/:data', 
    component: RegisterViewEN
  }, */
  { 
    path: '/registration', 
    component: RegistrationView
  },
  { 
    path: '/registration/en', 
    component: RegistrationViewEN
  }
]
const router = new VueRouter({routes})
export default router